import React from "react";
import Img4 from "../../../assets/img/img4.webp";
import "./SectionSix.css"

const SectionSixComponent = () => {
  return (
    <div className="row m-0 section section-six">
      <div className="col-12 col-md-6">
        <h1 className="section-title">TODO LO QUE</h1>
        <h1 className="section-title">NECESITAS DESDE</h1>
        <h1 className="section-title">UN MISMO LUGAR</h1>
        <p className="section-paragraph">
          Podrás realizar tus inicios/cierres de turno,
          <br />
          registrar paquetes de productos o precios
          <br />
          especiales por repetir productos.
        </p>
      </div>
      <div className="col-12 col-md-6">
        <div className="section-image">
          <img
            src={Img4}
            alt="img4"
            width="auto"
            height="auto"
            className="img img-fluid"
          />
        </div>
      </div>
    </div>
  );
};

export default SectionSixComponent;

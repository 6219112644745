const Contact = ({
  name,
  email,
  phone,
  company,
  comments,
}) => `
<div style="font-family: Verdana, Geneva, Tahoma, sans-serif; margin: 0% !important; color: #000000 !important">
  <h1 style="display: flex; flex-direction: row; color: #413db0; padding: 10px;">Información de Contacto</h1>
  <p style="display: block; padding: 10px; color: #000000;">
    A quien corresponda:<br/>
    Le hacemos llegar la siguiente información de contacto.
  </p>
  <ul style="color: #000000 !important;">
    <li><strong>Nombre:</strong> ${name}</li>
    <li><strong>Correo:</strong> ${email}</li>
    <li><strong>Teléfono:</strong> ${phone}</li>
    <li><strong>Compañia:</strong> ${company}</li>
    <li><strong>Comentarios:</strong> ${comments}</li>
  </ul>
  <footer style="background: #413db0; color: #ffffff !important;">
    <div style="padding: 10px; color: #ffffff !important">
      <p>
        Gracias por elegir <strong>HG Consulting.</strong><br/>
        Si tienes alguna pregunta o necesitas asistencia adicional, no dudes en contactarnos.
      </p>
      <div style="padding-bottom: 5px;">
        <span><strong>Dirección:</strong></span>
        <span>
          C. Islote 2490, Bosques de La Victoria, 44538 Guadalajara, Jal.
        </span>
      </div>
      <div style="padding-bottom: 5px;">
        <span><strong>Teléfono:</strong></span>
        <span>(331) 810 0099</span>
      </div>
      <div style="padding-bottom: 5px;">
        <span><strong>Correo:</strong></span>
        <span style="text-decoration: none; color: #ffffff !important;">hola@checkpos.mx</span>
      </div>
    </div>
    <span style="display: block; padding: 10px; font-size: 12px; background: #3d3aa9;">CHECKPOST DERECHOS RESERVADOS &copy; ${new Date().getFullYear()}</span>
  </footer>
</div>`;

export default Contact;

import React from "react";
import Icon4 from "../../../assets/img/icon4.png";
import Icon5 from "../../../assets/img/icon5.png";
import "./SectionFive.css";
const SectionFiveComponent = () => {
  return (
    <div className="row m-0 section section-five section-bg-secondary">
      <div className="mask"></div>
      <div className="col-12 col-md-6 col-sm-12">
        <h1 className="section-title">SUPERVISA Y EVALUA</h1>
        <h1 className="section-title">EL DESEMPEÑO DE TU</h1>
        <h1 className="section-title">NEGOCIO DE MANERA</h1>
        <h1 className="section-title">DETALLADA</h1>
        <p className="section-paragraph text-nowrap">
          Mantén un control preciso sobre cada
          <br />
          aspecto operativo y direcciona tu negocio
          <br />
          hacia el éxito con confianza.
        </p>
      </div>
      <div className="col-12 col-md-6 col-sm-12">
        <div className="section-actions">
          <div className="section-action-item">
            <div className="col-12 col-md-4 m-auto text-center">
              <img src={Icon4} alt="icon4" width="96px" height="96px" />
            </div>
            <div className="col-12 col-md-8 text">
              <p className="section-paragraph">
                Podrás ver de manera rápida y <br />
                sencilla el&nbsp;
                <strong className="bold">
                  progreso de tu negocio
                  <br />
                  en tiempo real
                </strong>
                &nbsp; o incluso filtrarlo por
                <br />
                semano o mes en curso.
              </p>
            </div>
          </div>
          <div className="section-action-item">
            <div className="col-12 col-md-4 m-auto text-center">
              <img src={Icon5} alt="icon5" width="96px" height="96px" />
            </div>
            <div className="col-12 col-md-8">
              <p className="section-paragraph">
                <strong className="bold">
                  Evalúa el trabajo de tus cajeros,
                </strong>
                <br />
                visualizando la venta del turno, la
                <br />
                apertura de caja, la venta que se <br />
                propinas, descuentos, cancelaciones
                <br />y mucho más
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionFiveComponent;

import React from "react";
import "./SectionThree.css"
const SectionThreeComponent = () => {
  return (
    <div className="row m-0 section section-three">
      <div className="col-12 col-md-6">
        <h1 className="section-title">PAGA TUS MESAS DE</h1>
        <h1 className="section-title">MANERA SENCILLA Y</h1>
        <h1 className="section-title">EFICIENTE</h1>
        <p className="section-paragraph">
          Usa los métodos de pago que necesites,
          <br />
          incluyendo propinas con cualquiera de los
          <br />
          métodos de pago.
        </p>
      </div>
    </div>
  );
};

export default SectionThreeComponent;

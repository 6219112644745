import React from "react";
import Logo2 from "../../../assets/img/logo2.png";
import Icon6 from "../../../assets/img/icon6.png";
import Icon7 from "../../../assets/img/icon7.png";
import Icon8 from "../../../assets/img/icon8.png";
import "./SectionEight.css";

const SectionEightComponent = ({ isHidden }) => {
  return (
    <div className="row m-0 section-eight">
      <div className="col-12 col-md-auto">
        <div className="section-image">
          <img src={Logo2} alt="logo" width="192px" height="auto" />
        </div>
        <div>
          <p className="section-paragraph">Tu punto de venta ideal</p>
        </div>
        <div className="section-icons">
          <div className="section-icon-item">
            <a
              href="https://www.facebook.com/HGCMEX"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Icon6} alt="icon6" width="64px" height="64px" />
            </a>
          </div>
          <div className="section-icon-item">
            <a
              href="https://www.linkedin.com/company/hg-consulting-mx/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Icon7} alt="icon7" width="64px" height="64px" />
            </a>
          </div>
          <div className="section-icon-item">
            <a
              href="https://instagram.com/hg.consulting?igshid=MzRlODBiNWFlZA=="
              target="_blank"
              rel="noreferrer"
            >
              <img src={Icon8} alt="icon8" width="64px" height="64px" />
            </a>
          </div>
        </div>
      </div>
      {!isHidden && (
        <div className="col-12 col-md-auto">
          <h1 className="section-subtitle">INFORMACIÓN</h1>
          <ul className="section-list">
            <li>Nosotros</li>
            <li>Preguntas frecuentes</li>
            <li>Términos y Condiciones</li>
            <li>Aviso de Privacidad</li>
          </ul>
        </div>
      )}
      <div className="col-12 col-md-auto">
        <h1 className="section-subtitle">
          SERVICIOS AL <br />
          CONSUMIDOR
        </h1>
        <ul className="section-list">
          <li>Facturación Electrónica</li>
          <li>Aviso de privacidad</li>
          <li>Sugerencias y Quejas</li>
          <li>Cambios y Devoluciones</li>
          <li>Sobre los PDV</li>
        </ul>
      </div>
      <div className="col-12 col-md-auto">
        <h1 className="section-subtitle">CONTACTO</h1>
        <div className="section-list">
          <div className="section-list-item">
            <span>
              <i className="bi bi-geo-alt-fill"></i>
            </span>
            <span className="text-wrap">
              C. Islote 2490, Bosques de La Victoria,
              <br /> 44538 Guadalajara, Jal.
            </span>
          </div>
          <div className="section-list-item">
            <a
              className="btn bg-white text-decoration-none text-color-primary"
              href="https://goo.gl/maps/6CJbEw7x6Yppu3CVA"
              target="_blank"
              rel="noreferrer"
            >
              Ver en el mapa
            </a>
          </div>
          <div className="section-list-item">
            <span>
              <i className="bi bi-envelope-fill"></i>
            </span>
            <span>hola@checkpos.mx</span>
          </div>
          <div className="section-list-item">
            <span>
              <i className="bi bi-telephone-fill"></i>
            </span>
            <span>33 1810 0099</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionEightComponent;

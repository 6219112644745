import "./App.css";
import HeaderComponent from "../components/header/HeaderComponent";
import CarouselComponent from "../components/carousel/CarouselComponent";
import SectionEightComponent from "../components/sections/section_eight/SectionEightComponent";
import SectionSevenComponent from "../components/sections/section_seven/SectionSevenComponent";
import SectionSixComponent from "../components/sections/section_six/SectionSixComponent";
import SectionFiveComponent from "../components/sections/section_five/SectionFiveComponent";
import SectionFourComponent from "../components/sections/section_four/SectionFourComponent";
import SectionThreeComponent from "../components/sections/section_three/SectionThreeComponent";
import SectionTwoComponent from "../components/sections/section_two/SectionTwoComponent";
import SectionOneComponent from "../components/sections/section_one/SectionOneComponent";
import FormComponent from "../components/form/FormComponent";

function App() {
  const isHidden = true;

  return (
    <>
      <HeaderComponent isHidden={isHidden} />
      <CarouselComponent />
      <SectionOneComponent />
      <SectionTwoComponent />
      <SectionThreeComponent />
      <SectionFourComponent />
      <SectionFiveComponent />
      <SectionSixComponent />
      <SectionSevenComponent />
      <FormComponent />
      <SectionEightComponent isHidden={isHidden} />
      <div className="col-12 col-md-12">
        <div className="section-footer">CHECKPOST DERECHOS RESERVADOS &copy; {new Date().getFullYear()}</div>
      </div>
      <div className="floating-btn">
        <a className="btn btn-success rounded-5" href="https://wa.link/w5kf52" target="_blank" rel="noreferrer">
          <i className="bi bi-whatsapp fs-5"></i>
        </a>
      </div>
    </>
  );
}

export default App;

import React from "react";
import Img1 from "../../../assets/img/img1.webp";
import "./SectionOne.css";

const SectionOneComponent = () => {
  return (
    <div className="row m-0 section section-one">
      <div className="col-12 col-md-6">
        <h1 className="section-title">DESATA EL</h1>
        <h1 className="section-title">PONTENCIAL DE TU</h1>
        <h1 className="section-title">NEGOCIO</h1>
        <p className="section-paragraph">
          Ve catálogos de insumos, productos, modificadores,
          <br />
          promociones, clientes, usuarios y más.
        </p>
      </div>
      <div className="col-12 col-md-6">
        <div className="section-image">
          <img src={Img1} alt="img1" className="img img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default SectionOneComponent;

import React from "react";
import Img3 from "../../../assets/img/img3.webp";
import "./SectionFour.css";

const SectionFourComponent = () => {
  return (
    <div className="row m-0 section section-four">
      <div className="col-12 col-md-6 section-image">
        <img
          src={Img3}
          className="img img-fluid"
          alt="img3"
          width="auto"
          height="auto"
        />
      </div>
      <div className="col-12 col-md-6">
        <h1 className="section-title">MANEJA Y</h1>
        <h1 className="section-title">PERSONALIZA TUS</h1>
        <h1 className="section-title">PROMOCIONES</h1>
        <h1 className="section-title">DESDE EL PDV</h1>
        <p className="section-paragraph">
          Selecciona productos a precio especial,
          <br />
          paquetes de productos o precios
          <br />
          especiales por repetir productos.
        </p>
      </div>
    </div>
  );
};

export default SectionFourComponent;

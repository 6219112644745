import React from "react";
import Logo from "../../assets/img/logo.png";
import "./Header.css";

const HeaderComponent = ({ isHidden }) => {
  return (
    <>
      <div className="header">
        <div className="col-12 col-sm-12 col-md-auto m-auto text-center">
          <img
            className="img img-fluid"
            src={Logo}
            alt="Logo"
            width="auto"
            height="auto"
          />
        </div>
        <div className="col-12 col-sm-12 col-md-auto m-auto text-center">
          <h4 className="text-white">Tu punto de venta ideal</h4>
        </div>
        <div className="col-12 col-sm-12 col-md-auto m-auto text-center">
          <div className="d-flex flex-row flex-wrap gap-4">
            <div>
              <a
                className="btn bg-white text-decoration-none text-nowrap"
                href="https://chat.whatsapp.com/ByH8UTbwuDtDnakNCsDr3b"
                target="_blank"
                rel="noreferrer"
              >
                Más información
              </a>
            </div>
            <div className="col-12 col-sm-12 col-md-auto m-auto text-center">
              <a
                className="text-white text-decoration-none text-nowrap"
                href="https://tienda.checkpos.com.mx"
                target="_blank"
                rel="noreferrer"
              >
                Tienda
              </a>
            </div>
          </div>
        </div>
      </div>
      {!isHidden && (
        <div className="horizontal-divider">
          <span></span>
        </div>
      )}
      {!isHidden && (
        <nav className="navbar navbar-expand-lg navbar-light">
          <a className="navbar-brand" href="/#">
            Inicio
          </a>
          <a className="navbar-brand" href="#informacion">
            Introducción
          </a>
          <a className="navbar-brand" href="#sobre-nosotros">
            Sobre Nosotros
          </a>
          <a className="navbar-brand" href="#soluciones">
            Soluciones
          </a>
          <a className="navbar-brand" href="#formulario">
            Formulario
          </a>
          {!isHidden && (
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
          )}
          {!isHidden && (
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item active">
                  <a class="nav-link" href="..">
                    Home <span class="sr-only">(current)</span>
                  </a>
                </li>
              </ul>
            </div>
          )}
        </nav>
      )}
    </>
  );
};

export default HeaderComponent;

import React from "react";
import Slider1 from "../../assets/img/slider1.webp";
import Slider2 from "../../assets/img/slider2.webp";
import Slider3 from "../../assets/img/slider3.webp";
import "./Carousel.css";

const CarouselComponent = () => {
  return (
    <div
      id="carouselExampleCaptions"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img
            src={Slider1}
            className="d-block w-100 img img-fluid"
            alt="..."
          />
          <div className="carousel-caption d-none d-md-block">
            <h1 className="slider-title">
              COCINA EXCEPCIONAL,
              <br />
              OPERACIONES IMPECABLES
            </h1>
            <p className="slider-subtitle">
              Descubre nuestro sistema de punto de venta
            </p>
            <button className="slider-button">
              <a
                className="text-decoration-none text-white"
                href="https://chat.whatsapp.com/ByH8UTbwuDtDnakNCsDr3b"
                target="_blank"
                rel="noreferrer"
              >
                Saber más
              </a>
            </button>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src={Slider2}
            alt="..."
            className="d-block w-100 img img-fluid"
          />
          <div className="carousel-caption d-none d-md-block">
            <h1 className="slider-title">
              TRANSFORMA TU NEGOCIO CON
              <br />
              UN ERP TODO EN UNO
            </h1>
            <p className="slider-subtitle">
              Descubre cómo Odoo, la solución de planificación de recursos
              <br />
              empresariales (ERP) líder en el mercado, revolucióna la forma
              <br />
              en que gestionas y hacer crecer tu negocio.
            </p>
            <button className="slider-button">
              <a
                className="text-decoration-none text-white"
                href="https://chat.whatsapp.com/ByH8UTbwuDtDnakNCsDr3b"
                target="_blank"
                rel="noreferrer"
              >
                Saber más
              </a>
            </button>
          </div>
        </div>
        <div className="carousel-item">
          <img src={Slider3} alt="..." className="d-block w-100img img-fluid" />
          <div className="carousel-caption d-none d-md-block">
            <h1 className="slider-title">
              SABOREA EL ÉXITO EN TU <br />
              NEGOCIO
            </h1>
            <p className="slider-subtitle">
              Desde la administración de inventario hasta la orquestación de
              <br />
              promociones especiales, Odoo abarca todos los aspectos esenciales
              <br />
              de tu negocio en una plataforma integrada.
            </p>
            <button className="slider-button">
              <a
                className="text-decoration-none text-white"
                href="https://chat.whatsapp.com/ByH8UTbwuDtDnakNCsDr3b"
                target="_blank"
                rel="noreferrer"
              >
                Saber más
              </a>
            </button>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default CarouselComponent;

import React from "react";
import Icon1 from "../../../assets/img/icon1.png";
import Icon2 from "../../../assets/img/icon2.png";
import Icon3 from "../../../assets/img/icon3.png";
import "./SectionTwo.css";

const SectionTwoComponent = () => {
  return (
    <div className="row m-0 section section-two section-bg-primary">
      <div className="col-md-12">
        <div className="mask"></div>
        <h1 className="section-title">OPTIMIZA TU</h1>
        <h1 className="section-title">RESTAURANTE O NEGOCIO</h1>
        <p className="section-paragraph text-wrap">
          Descubre las diferentes herramientas que CheckPOS tiene para ti
        </p>
      </div>
      <div className="section-actions">
        <div className="section-action-item">
          <div className="section-action-image">
            <img
              src={Icon1}
              alt="icon1"
              width="128px"
              height="128px"
              className="img img-fluid"
            />
          </div>
          <div className="section-action-text">
            <p className="section-paragraph">
              Control de entradas <br />
              salidas de usuarios
            </p>
          </div>
        </div>
        <div className="section-action-item">
          <div className="section-action-image">
            <img
              src={Icon2}
              alt="icon2"
              width="128px"
              height="128px"
              className="img img-fluid"
            />
          </div>
          <div className="section-action-text">
            <p className="section-paragraph">
              Control de diferentes <br />
              estatus de mesa
            </p>
          </div>
        </div>
        <div className="section-action-item">
          <div className="section-action-image">
            <img
              src={Icon3}
              alt="icon3"
              width="128px"
              height="128px"
              className="img img-fluid"
            />
          </div>
          <div className="section-action-text">
            <span className="section-paragraph">
              Menú a 3 escalas <br />
              <ul className="section-list">
                <li>familias</li>
                <li>categorías</li>
                <li>productos</li>
              </ul>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwoComponent;

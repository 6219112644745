import React from "react";
import Img6 from "../../../assets/img/img6.png";
import "./SectionSeven.css";

const SectionSevenComponent = () => {
  return (
    <div className="row m-0 section section-seven">
      <div className="col-12 col-md-6 section-seven-text">
        <div>
          <h1 className="section-title">VINCULA TU PUNTO DE</h1>
          <h1 className="section-title">VENTA CON UN</h1>
          <h1 className="section-title">POTENTE ERP</h1>
        </div>
        <div className="section-image">
          <img src={Img6} alt="img6" className="img img-fluid" />
        </div>
        <div>
          <p className="section-paragraph text-nowrap">
            Al unir tu punto de venta con el potente ERP
            <br />
            Odoo, lleva tu negocio a un nuevo nivel.
            <br />
            Sincroniza ventas, inventario y más, logrando
            <br />
            una coordinación sin igual que impulsa la
            <br />
            eficiencia y decisiones más informadas.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SectionSevenComponent;

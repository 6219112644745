const Confirmation = ({name}) => `
<div style="font-family: Verdana, Geneva, Tahoma, sans-serif; margin: 0% !important; color: #000000 !important">
    <h1 style="display: flex; flex-direction: row; color: #413db0; padding: 10px;">
        Confirmación de envio
    </h1>
    <p style="display: block; padding: 10px; color: #000000;">
        Hola <strong>${name}</strong>, buen día.<br /><br/>
        Le confirmamos que su solicitud de información ha sido enviada correctamente,<br/>
        pronto nos pondremos en contacto para brindarle más información<br/>
        acerca de nuestro punto de venta y servicios qué tenemos para usted.
    </p>
    <footer style="background: #413db0; color: #ffffff !important;">
        <div style="padding: 10px; color: #ffffff !important">
            <p>
                Gracias por elegir <strong>HG Consulting.</strong><br />
                Si tienes alguna pregunta o necesitas asistencia adicional, no dudes
                en contactarnos.
            </p>
            <div style="padding-bottom: 5px;">
                <span><strong>Dirección:</strong></span>
                <span>
                    C. Islote 2490, Bosques de La Victoria, 44538 Guadalajara, Jal.
                </span>
            </div>
            <div style="padding-bottom: 5px;">
                <span><strong>Teléfono:</strong></span>
                <span>(331) 810 0099</span>
            </div>
            <div style="padding-bottom: 5px;">
                <span><strong>Correo:</strong></span>
                <span style="text-decoration: none; color: #ffffff !important;">hola@checkpos.mx</span>
            </div>
        </div>
        <span style="display: block; padding: 10px; font-size: 12px; background: #3d3aa9;">
            CHECKPOST DERECHOS RESERVADOS &copy; ${new Date ().getFullYear ()}
        </span>
    </footer>
</div>`;

export default Confirmation;

import React, {useState} from 'react';
import * as yup from 'yup';
import {useFormik} from 'formik';
import './Forms.css';
import {sendEmail} from '../../services/services';
import Contact from '../template/Contact';
import Confirmation from '../template/Confirmation';

const FormComponent = () => {
  const [isSending, setIsSending] = useState (false);
  const [isSuccess, setIsSuccess] = useState (false);
  const [isError, setIsError] = useState (false);

  const formik = useFormik ({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      company: '',
      comments: '',
    },
    validationSchema: yup.object ({
      name: yup
        .string ('Ingrese su nombre.')
        .required ('Su nombre es requerido.'),
      email: yup
        .string ('Ingrese su correo.')
        .email ('Su correo ingresado no es valido.')
        .required ('Su correo es requerido.'),
      phone: yup
        .string ('Ingrese su teléfono.')
        .required ('Su teléfono es requerido.'),
      company: yup
        .string ('Ingrese el nombre de su compañia.')
        .required ('El nombre de su compañia es requerido.'),
      comments: yup.string ('Ingrese sus comentarios'),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async values => {
      setIsSending (true);
      setIsSuccess (false);
      setIsError (false);
      const data = {
        to: process.env.REACT_APP_EMAIL_TO,
        subject: 'CheckPOS Contacto Venta',
        content: Contact (values).toString (),
      };

      await sendEmail (data)
        .then (async response => {
          if (response.status === 200) {
            const data = {
              to: values.email,
              subject: 'CheckPOS Confirmación de Envio',
              content: Confirmation (values).toString (),
            };

            await sendEmail (data)
              .then (response => {
                if (response.status === 200) {
                  setIsSending (false);
                  setIsSuccess (true);
                  formik.handleReset ();
                }
              })
              .catch (() => {
                setIsSending (false);
                setIsError (true);
              });
          }
        })
        .catch (() => {
          setIsSending (false);
          setIsError (true);
        });
    },
  });

  const isInvalid = name => {
    return [
      'form-control',
      formik.submitCount !== 0
        ? Boolean (formik.errors[name]) ? 'is-invalid' : ''
        : '',
    ].join (' ');
  };

  return (
    <div className="section section-form">
      <h1 className="section-title">Contacto:</h1>
      <form
        onSubmit={formik.handleSubmit}
        className="row g-2 needs-validation"
        noValidate
      >
        <div className="col-12 col-md-6 col-sm-6 mb-2">
          <label htmlFor="name" className="form-label">
            Nombre: <strong className="text-danger">*</strong>
          </label>
          <input
            className={isInvalid ('name')}
            id="name"
            name="name"
            type="text"
            placeholder="Ingrese su nombre"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleChange}
            required
          />
          <div className="invalid-feedback">{formik.errors.name}</div>
        </div>
        <div className="col-12 col-md-6 col-sm-6 mb-2">
          <label htmlFor="email" className="form-label">
            Correo: <strong className="text-danger">*</strong>
          </label>
          <input
            className={isInvalid ('email')}
            id="email"
            name="email"
            type="email"
            placeholder="Ingrese su correo electrónico"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleChange}
            required
          />
          <div className="invalid-feedback">{formik.errors.email}</div>
        </div>
        <div className="col-12 col-md-6 col-sm-6 mb-2">
          <label htmlFor="phone" className="form-label">
            Teléfono: <strong className="text-danger">*</strong>
          </label>
          <input
            className={isInvalid ('phone')}
            id="phone"
            name="phone"
            type="tel"
            placeholder="Ingrese su numero de teléfono"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleChange}
            required
          />
          <div className="invalid-feedback">{formik.errors.phone}</div>
        </div>
        <div className="col-12 col-md-6 col-sm-6 mb-2">
          <label htmlFor="company" className="form-label">
            Compañia: <strong className="text-danger">*</strong>
          </label>
          <input
            className={isInvalid ('company')}
            id="company"
            name="company"
            type="text"
            placeholder="Ingrese el nombre de su compañia"
            value={formik.values.company}
            onChange={formik.handleChange}
            onBlur={formik.handleChange}
            required
          />
          <div className="invalid-feedback">{formik.errors.company}</div>
        </div>
        <div className="col-12 col-md-12 col-sm-12 mb-2">
          <label htmlFor="comments" className="form-label">
            Comentario: (Opcional)
          </label>
          <textarea
            className="form-control"
            id="comments"
            name="comments"
            rows={6}
            placeholder="Ingrese sus comentarios"
            value={formik.values.comments}
            onChange={formik.handleChange}
            onBlur={formik.handleChange}
          />
        </div>
        <div className="col-12 col-md-12 col-sm-12 center">
          <button
            className="btn btn-secondary"
            type="submit"
            disabled={isSending}
          >
            {isSending ? 'Enviando...' : 'Enviar solicitud'}
          </button>
          {isSuccess &&
            <span className="form-sendmail-successfull">
              Correo enviado correctamente.
            </span>}
          {isError &&
            <span className="form-sendmail-error">
              El correo no ha sido enviado correctamente.
            </span>}
        </div>
      </form>
    </div>
  );
};

export default FormComponent;
